.mywork-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    overflow: auto;

    @media (max-width: 767px) {
        margin-top: 25%;

    }

    .mywork-header {
        font-size: 53px;
        color: #Ffd700;
        font-family: 'Coolvetica';
        font-weight: 400;

        margin-bottom: 20px;

        @media (max-width: 767px) {
            font-size: 50px;
        }
    }
}

.bot-tag-static {
    position: static !important;
    margin-left: 120px;

    @media (max-width: 767px) {
        margin-left: 50px;
    }
}

.bot-tag-static-html {
    margin-left: 100px !important;

    @media (max-width: 767px) {
        margin-left: 30px !important;
    }
}