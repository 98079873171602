.resume-page {
    
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12.5rem;

    @media (max-width: 767px) {
        width: 85%;
        margin-top: 8rem;
    }

    .resume-header {
        font-size: 53px;
        color: #Ffd700;
        font-family: 'Coolvetica';
        font-weight: 400;
        text-align: center;

        margin-bottom: 20px;

        @media (max-width: 767px) {
            font-size: 50px;
        }
    }

    .resume-pages {
        margin-bottom: 50px;

        .resume-page1 {
            padding: 25px;

            @media (max-width: 767px) {
                padding: 0;
                padding-bottom: 20px;
            }
        }
        .resume-page2 {
            padding: 25px;
            @media (max-width: 767px) {
                padding: 0;
                padding-bottom: 20px;
            }
        }
    }
}