.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 20%;
  bottom: 0;
  left: auto;
  margin: auto;
}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    z-index: 1;
    box-shadow: 15px 20px 8px 15px rgba(0, 0, 0, 0.1); /* Add a drop shadow */
    animation: fadeIn 5s ease-in-out;
  }

