.mobile-nav-bar {
  background: #181818;
  top: 0;
  z-index: 3;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      font-size: 19px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: 58px;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 1px;
        position: absolute;
        bottom: 0;
        display: block;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
        margin-left: -10px;
      }

      &:first-child {
        &::after {
          content: 'HOME';
          margin-left: 0px;
        }
      }
    }

    a.mobile-about-link {
      &:after {
        content: 'ABOUT';
        margin-left: 0px;
      }
    }

    a.mobile-resume-link {
      &:after {
        content: 'RESUME';
      }
    }
    a.mobile-my-work-link {
      &:after {
        content: 'MY WORK';
      }
    }

    a.mobile-contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    bottom: 20px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
  }

  li {
    a {
      padding: 7px 0;
      display: block;
      font-size: 15px;
      line-height: 16px;

      &:hover svg {
        color: #ffd700;
      }
    }
  }

  .hamburger-menu {
    position: absolute;
    z-index: 5;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    color: #4d4d4e;
    display: block;
    line-height: 51px;
    height: 51px;
    text-decoration: none;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}