.project {
    display: flex;
    align-items: center;
    width: 85%;
    height: 75%;
    margin-bottom: 75px;
    border: 1px solid #323f54;
    border-radius: 10px;
    box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.5);
    background-color: #323f54;
    animation: pulse 1s;

    &:nth-of-type(1) {
        animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
        animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
        animation-delay: 1.3s;
    }

    &:nth-of-type(4) {
        animation-delay: 1.4s;
    }

    @media (max-width: 767px) {
        display: block;
    }

    .project-left {
        align-self: start;
        width: 45%;
        margin-right: 20px;
        padding: 10px;

        @media (max-width: 767px) {
            width: auto;
            text-align: center;
        }

        .project-name {
            font-size: 53px;
            color: #Ffd700;
            font-family: 'Coolvetica';
            font-weight: 400;

            @media (max-width: 767px) {
                font-size: 30px;
            }
        }

        .project-contribution {
            font-size: 25px;
            color: #Ffd700;
            font-family: 'Coolvetica';
            font-weight: 400;

            @media (max-width: 767px) {
                font-size: 15px;
                text-align: center;
            }
        }

        .project-description {
            font-size: 25px;
            color: #fff;
            font-family: 'Coolvetica';
            font-weight: 300;
            min-width: fit-content;
            padding-left: 50px;

            @media (max-width: 767px) {
                font-size: 15px;
                list-style-type: none;
                padding-left: 20px;
            }
        }

        .project-url {
            text-decoration: none;
            cursor: pointer;

            h1:hover {
                color: #fff;
            }
        }


    }

    .project-right {
        width: 55%;
        overflow: hidden;
        padding: 10px;

        @media (max-width: 767px) {
            width: auto;
        }
    }

}